import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import lockicon from "../../Assets/lock_icon.png";
import * as API from "../../Service/API/index";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import "./LoginOTP.css";
const LoginOTP = (props) => {
  console.log("props.location.state", props.location.state);
  const history = useHistory();
  const [getOTP, setOTP] = React.useState("");
  const [loader, setloader] = React.useState(false);
  const [errorOTP, seterrorOTP] = React.useState(false);
  const [errorSuccessOTP, seterrorSuccessOTP] = React.useState(false);
  const [ErrorOTPmess, setErrorOTPmess] = React.useState(false);

  const handleOTP = (e) => {
    setOTP(e.target.value);
  };

  const SubmitOTP = (e) => {
    if (getOTP == "") {
      seterrorOTP(true);
    } else {
      setloader(true);
      seterrorOTP(false);
      let data = {
        otp: getOTP,
      };
      API.ValidateOTP_API(data, (res) => {
        console.log(res);
        if (res.statusCode == "200") {
          setloader(false);
          localStorage.setItem("pdf", res.info.digitalCardURL);
          localStorage.setItem("wallet", res.info.walletPdfUrl);
          localStorage.setItem("applestate", res.info.applePassUrl);
          localStorage.setItem("googlestate", res.info.googlePassUrl);

          history.push({
            pathname: "/pdf",
          });
        } else {
          setloader(false);
          setErrorOTPmess(true);
          seterrorSuccessOTP(false);
        }
      });
    }
  };
  const ResendOTP = () => {
    setloader(true);
    let data = {
      email: props.location.state.email,
      mobileNumber: props.location.state.mobileNumber,
      messageCode: null,
      patientId: props.location.state.patientId,
      firstName: props.location.state.firstName,
    };
    API.SendOTP_API(data, (res) => {
      if (res.statusCode == "200") {
        seterrorOTP(false);
        setloader(false);
        seterrorSuccessOTP(true);
      } else {
        setloader(false);
      }
    });
  };
  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      SubmitOTP();
    }
  };
  return (
    <>
      <ToastContainer />
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="Login_OTP_container">
            <Grid item xs={12} md={12} sm={12}>
              <img src={lockicon} className="lockicon" />
            </Grid>

            <p>Enter the verification code we just sent to your</p>
            <p>email / mobile number</p>

            <div className="otpText">
              <Grid item xs={12} md={12} sm={12}>
                <div style={{ textAlign: "center" }}>
                  <TextField
                    style={{
                      width: "60%",
                      textAlign: "center",
                    }}
                    className="form_textfield"
                    autoComplete="off"
                    value={getOTP}
                    onChange={handleOTP}
                    onKeyUp={EnterKeynext}
                  />
                  {errorOTP ? (
                    <div className="errorText">Please enter OTP</div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Grid>
            </div>
            <div className="resend">
              <Grid item xs={12} md={12} sm={12}>
                <div onClick={ResendOTP}>RESEND</div>
                {errorSuccessOTP ? (
                  <div className="SuccessText"> OTP sent successfully</div>
                ) : (
                  <div></div>
                )}
              </Grid>
            </div>
          </div>

          <div className="Login_OTP_btn_box my">
            <button className="btn" onClick={SubmitOTP}>
              Next
            </button>
          </div>
          {ErrorOTPmess ? (
            <div style={{ textAlign: "center" }} className="errorText">
              Invalid OTP
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </>
  );
};

export default LoginOTP;

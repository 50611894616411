import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomerOnboard from "../../Assets/CustomerOnboard.svg";
import CircularProgress from "@mui/material/CircularProgress";
import * as API from "../../Service/API/index.js";
import { toast, ToastContainer } from "react-toastify";
// import { CountryRegionData } from "react-country-region-selector";
import "react-toastify/dist/ReactToastify.css";

const CustomerCreate = () => {
  const [accountType, setAccounttype] = useState([
    "custom",
    "express",
    "standard",
  ]);
  const [accountTypevalue, setAccounttypevalue] = useState("express");
  const [country, setCountry] = useState("US");
  const [BusinessName, setBusinessName] = useState("");
  const [loader, setloader] = React.useState(false);
  const [countryList, setCountryList] = React.useState([]);
  //Error States
  const [accountTypeError, setaccountTypeError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [BusinessNameError, setBusinessNameError] = useState(false);

  //======Get CountryList======//
  const GetCountryList = () => {
    API.GET_BVI_COUNTRYLIST((res) => {
      setCountryList(res.info);
    });
  };
  useEffect(() => {
    GetCountryList();
  }, []);

  const submit = (e) => {
    e.preventDefault();
    setaccountTypeError(false);
    setCountryError(false);
    setBusinessNameError(false);
    if (accountTypevalue == "") {
      setaccountTypeError(true);
    } else if (country == "") {
      setCountryError(true);
      setaccountTypeError(false);
    } else if (BusinessName.trim() == "") {
      setBusinessNameError(true);
      setaccountTypeError(false);
      setCountryError(false);
    } else {
      console.log(">>>>>>> API call >>>>>>>>>>");
      setloader(true);
      let data = {
        accountType: accountTypevalue,
        countryCode: country,
        businessName: BusinessName.trim(),
        returnUrl:
          process.env.REACT_APP_CUSTOMER_SUCCESS_URL +
          "customer-onboard-success",
      };
      API.CUSTOMER_CREATE(data, (res) => {
        if (res.statusCode === 200) {
          window.location.href = res.redirectLink;
        } else {
          setloader(false);
          toast.error(res.errorMessage);
        }
      });
    }
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="form_container">
        <div className="form_body">
          <Grid container className="mt">
            <Grid item xs={12} md={12} sm={12}>
              <Typography className="mb" variant="h6">
              beaconBVI - Stripe Business onboarding
              </Typography>
              <div className="form_back">
                <div className="form_overlay">
                  {loader == true ? (
                    <div className="loaderAdj">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      <Typography className="pt mx" variant="subtitle1">
                        Customer Information
                      </Typography>
                      <Grid container spacing={4} className="px py">
                        <Grid item xs={12} md={7} sm={12}>
                          <div className="payment_svg_bg">
                            <img
                              style={{ width: "82%" }}
                              src={CustomerOnboard}
                              alt="CustomerOnboard"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={5} sm={12}>
                          <Grid container spacing={4} className="mt">
                            <Grid item xs={12} md={12} sm={12}>
                              <FormControl
                                className="form-dropdown"
                                fullWidth
                                variant="standard"
                              >
                                <InputLabel>Account Type *</InputLabel>
                                <Select
                                  name="accountType"
                                  label="Account Type *"
                                  value={accountTypevalue}
                                  style={{ textTransform: "capitalize" }}
                                  onChange={(e) =>
                                    setAccounttypevalue(e.target.value)
                                  }
                                >
                                  {accountType.map((item) => (
                                    <MenuItem
                                      key={item}
                                      value={item}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {accountTypeError == true ? (
                                <FormHelperText className="errorText">
                                  Please select the Account type
                                </FormHelperText>
                              ) : null}
                            </Grid>

                            <Grid item xs={12} md={12} sm={12}>
                              <FormControl
                                className="form-dropdown"
                                fullWidth
                                variant="standard"
                              >
                                <InputLabel>Country*</InputLabel>
                                <Select
                                  name="country"
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                  required
                                >
                                  {countryList.map((item) => (
                                    <MenuItem
                                      key={item.id}
                                      value={item.countryCode}
                                    >
                                      {item.name == "United States"?"United States - Puerto Rico": item.name}
                                    </MenuItem>
                                  ))}
                                  {/* {CountryRegionData.map((item) => (
                                    <MenuItem value={item[1]}>
                                      {item[0]}
                                    </MenuItem>
                                  ))} */}
                                </Select>
                              </FormControl>
                              {countryError == true ? (
                                <FormHelperText className="errorText">
                                  Please select the Country
                                </FormHelperText>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                              <TextField
                                className="form_textfield"
                                label="Business name*"
                                variant="standard"
                                name="businessName"
                                inputProps={{ maxLength: 30 }}
                                value={BusinessName}
                                onChange={(e) =>
                                  setBusinessName(e.target.value)
                                }
                                autoComplete="off"
                              />
                              {BusinessNameError == true ? (
                                <FormHelperText className="errorText">
                                  Please enter the Business name
                                </FormHelperText>
                              ) : null}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sm={12}
                              className="d-flex"
                            >
                              <button className="btn" onClick={submit}>
                                Next
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default CustomerCreate;

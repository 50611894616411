export const GET_VACCINATION_URL =
  process.env.REACT_APP_API_URL + "bvi/get/vaccination/configs";
export const GET_COUNTIES_URL =
  process.env.REACT_APP_API_URL + "public/get/state/counties";
export const GET_ORG_URL =
  process.env.REACT_APP_API_URL + "public/get/organizations";
export const GET_TEST_FORM_DROPDOWN_URL =
  process.env.REACT_APP_API_URL + "bvi/dropdown/list";
export const CHECK_DETAILS =
  process.env.REACT_APP_API_URL + "bvi/check/vax/details";
  export const VAX_NOT_DETAILS =
  process.env.REACT_APP_API_URL + "bvi/submit/not/vaccinated/test/details";
export const VAX_DETAILS_UPDATE =
  process.env.REACT_APP_API_URL + "bvi/details/submit";
export const TEST_DETAILS_UPDATE =
  process.env.REACT_APP_API_URL + "bvi/test/details/submit";
export const GET_BVI_LABS = process.env.REACT_APP_API_URL + "bvi/get/labs";
export const PAYMENT_API_URL =
  process.env.REACT_APP_API_URL + "bvi/payment/checkout";
export const GET_BVI_COUNTRYLIST =
  process.env.REACT_APP_API_URL + "bvi/country/list";
export const GET_BVI_STATELIST =
  process.env.REACT_APP_API_URL + "bvi/state/list";
export const GET_BVI_CITYLIST = process.env.REACT_APP_API_URL + "bvi/city/list";
export const CUSTOMER_CREATE =
  process.env.REACT_APP_API_URL + "bvi/payment/create/account";
export const GET_OTP_URL = process.env.REACT_APP_API_URL + "public/get/otp/to";
export const SENDOTP_URL = process.env.REACT_APP_API_URL + "public/send/otp";
export const VALIDATEOTP_URL =
  process.env.REACT_APP_API_URL + "public/validate/otp";

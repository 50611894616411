import "./App.css";
import Footer from "./Components/Footer/Footer";
import MainForm from "./Components/Form/MainForm";
import Header from "./Components/Header/Header";
import CustomerCreate from "./Components/Customer/CustomerCreate";
import CustomerSuccess from "./Components/Customer/CustomerSuccess";
import Login from "./Components/Auth/Login";
import LoginOTP from "./Components/Auth/LoginOTP";
import RetrivalCode from "./Components/Auth/RetrivalCode";
import PDFViewer from "./Components/Viewer/PDFViewer";
import WalletCard from "./Components/Viewer/WalletCard";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Route } from "react-router-dom";

const App = () => {
  return (
    <div className="main">
      <ToastContainer autoClose={2000} theme="colored" />
      <Header />
      <Router>
        <Route path="/" exact component={MainForm} />
        <Route path="/customer-onboard" exact component={CustomerCreate} />
        <Route
          path="/customer-onboard-success"
          exact
          component={CustomerSuccess}
        />
        <Route path="/login" exact component={Login} />
        <Route path="/login-otp" exact component={LoginOTP} />
        <Route path="/retrievalcode" exact component={RetrivalCode} />
        <Route path="/pdf" exact component={PDFViewer} />
        <Route path="/wallet-card" exact component={WalletCard} />
      </Router>

      <Footer />
    </div>
  );
};

export default App;

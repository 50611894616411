import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import StripePayment from "../../Assets/StripePayment.svg";

const CustomerSuccess = () => {
  return (
    <div className="form_container">
      <div className="form_body">
        <Grid container className="mt">
          <Grid item xs={12} md={12} sm={12}>
            <Typography className="mb" variant="h6">
              PolarisKey - Stripe Business onboarding
            </Typography>
            <div className="form_back">
              <div className="form_overlay">
                <Typography className="pt mx" variant="subtitle1">
                  Customer Onboarding success
                </Typography>

                <Grid container spacing={4} className="px py">
                  <Grid item xs={12} md={7} sm={12}>
                    <div className="payment_svg_bg">
                      <img
                        style={{ width: "75%" }}
                        src={StripePayment}
                        alt="StripePayment"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={5} sm={12}>
                    <Typography
                      className="pt mx success-text"
                      variant="subtitle1"
                    >
                      Your business account has been successfully added with
                      Hummingbird Health.
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CustomerSuccess;

import axios from "axios";
import * as API_URL from "../API-URL/index.js";

// Vaccine Types API
export function GetVaccination_API(res) {
  axios
    .get(API_URL.GET_VACCINATION_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
//Get Countrylist API
export function GET_BVI_COUNTRYLIST(res) {
  axios
    .get(API_URL.GET_BVI_COUNTRYLIST)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get STATElist API
export function GET_BVI_STATELIST(data, res) {
  axios
    .post(API_URL.GET_BVI_STATELIST + "?countryId=" + data.countryId, {})
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get CityList API
export function GET_BVI_CITYLIST(data, res) {
  axios
    .post(API_URL.GET_BVI_CITYLIST + "?stateId=" + data.stateId, {})
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get Counties API
export function GetCounties_API(res) {
  axios
    .get(API_URL.GET_COUNTIES_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get Vaccination names list
export function GetOrg_API(res) {
  axios
    .get(API_URL.GET_ORG_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
//Get LAB List
export function GET_BVI_LABS(res) {
  axios
    .get(API_URL.GET_BVI_LABS)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get Test Form dropdown details
export function Test_Form_Detail_API(res) {
  axios
    .get(API_URL.GET_TEST_FORM_DROPDOWN_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
//VAX_NOT_DETAILS
export function VAX_NOT_DETAILS(data, res) {
  axios
    .post(API_URL.VAX_NOT_DETAILS, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Check User exist or not
export function CHECK_DETAILS(data, res) {
  axios
    .post(API_URL.CHECK_DETAILS, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Update Vax details
export function VAX_DETAILS_UPDATE(data, res) {
  axios
    .post(API_URL.VAX_DETAILS_UPDATE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Update Test details
export function TEST_DETAILS_UPDATE(data, res) {
  axios
    .post(API_URL.TEST_DETAILS_UPDATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Payment Initiation
export function PAYMENT_API_URL(data, res) {
  axios
    .post(API_URL.PAYMENT_API_URL, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}


//CUSTOMER ONBOARD API
export function CUSTOMER_CREATE(data, res) {
  axios
    .post(API_URL.CUSTOMER_CREATE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

// Auth Send OTP

export function SendOTP_API(data, res) {
  axios
    .post(API_URL.SENDOTP_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

// Auth Validate OTP
export function ValidateOTP_API(data, res) {
  axios
    .post(API_URL.VALIDATEOTP_URL + "?otp=" + data.otp)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

// Auth Refresh Token
export function GetOTP_API(data, res) {
  axios
    .post(API_URL.GET_OTP_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

export const Validate = (value) => {
  const {
    firstName,
    lastName,
    email,
    newnumber,
    dialCode,
    dobmonth,
    dobdate,
    dobyear,
    arrivingLab,
    vaccinationStatus,
  } = value;
  let errors = false;

  if (firstName.trim() == "" && firstName.trim().length === 0) {
    errors = {
      isError: true,
      FieldName: "firstName",
      Message: "First Name cannot be blank",
    };
    return errors;
  } else if (lastName.trim() == "" && lastName.trim().length === 0) {
    errors = {
      isError: true,
      FieldName: "lastName",
      Message: "Last Name cannot be blank",
    };
    return errors;
  } else if (
    newnumber.slice(dialCode.length) === "" &&
    newnumber.slice(dialCode.length).length === 0
  ) {
    errors = {
      isError: true,
      FieldName: "phoneNumber",
      Message: "Mobile Number cannot be blank",
    };
    return errors;
  } else if (newnumber.slice(dialCode.length).length < 8) {
    errors = {
      isError: true,
      FieldName: "phoneNumber",
      Message: "Please enter the full digit mobile number",
    };
    return errors;
  } else if (email.trim() == "" && email.trim().length === 0) {
    errors = {
      isError: true,
      FieldName: "email",
      Message: "Email Id cannot be blank",
    };
    return errors;
  } else if (
    !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email.trim()) &&
    email.trim() != ""
  ) {
    errors = {
      isError: true,
      FieldName: "email",
      Message: "Please enter the valid Email",
    };
    return errors;
  } else if (dobmonth == "") {
    errors = {
      isError: true,
      FieldName: "dobmonth",
      Message: "DOB Month cannot be blank",
    };
    return errors;
  } else if (dobdate == "") {
    errors = {
      isError: true,
      FieldName: "dobdate",
      Message: "DOB Date cannot be blank",
    };
    return errors;
  } else if (dobyear == "") {
    errors = {
      isError: true,
      FieldName: "dobyear",
      Message: "DOB Year cannot be blank",
    };
    return errors;
  } else if (vaccinationStatus == "") {
    errors = {
      isError: true,
      FieldName: "vaccinationStatus",
      Message: "Please select the Vaccination Status",
    };

    return errors;
  } else if (arrivingLab == "") {
    errors = {
      isError: true,
      FieldName: "arrivingLab",
      Message: "Please select the arriving location",
    };

    return errors;
  }
};

export const ValidateData = (value) => {
  const {
    addressOne,
    city,
    zipCode,
    gender,
    ethnicity,
    member,
    occupation,
    state,
    country,
    county,
    countyValue,
    immigrationStatus,
    race,
    symptoms,
    symptomsChecked,
    symptomsStarteddate,
    myRef,
    myRef1,
    myRef2,
  } = value;
  let errors = false;
  let dummyarr = [];

  Object.keys(symptomsChecked).forEach(function (key) {
    dummyarr.push(symptomsChecked[key]);
  });

  if (addressOne.trim() == "" && addressOne.trim().length == 0) {
    myRef.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "addressOne",
      Message: "Address1 cannot be blank",
    };
    return errors;
  } else if (country.trim() == "" && country.trim().length == 0) {
    myRef.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "country",
      Message: "Please select the Country",
    };
    return errors;
  } else if (state.trim() == "" && state.trim().length == 0) {
    myRef.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "state",
      Message: "Please select the State",
    };
    return errors;
  } else if (
    countyValue.length &&
    county.trim() == "" &&
    county.trim().length == 0
  ) {
    myRef.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "county",
      Message: "Please select the County",
    };
    return errors;
  } else if (
    !countyValue.length &&
    city.trim() == "" &&
    city.trim().length == 0
  ) {
    myRef.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "city",
      Message: "City cannot be blank",
    };
    return errors;
  } else if (zipCode.trim() == "" && zipCode.trim().length == 0) {
    myRef.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "zipCode",
      Message: "ZipCode cannot be blank",
    };
    return errors;
  } else if (
    immigrationStatus.trim() == "" &&
    immigrationStatus.trim().length == 0
  ) {
    myRef.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "immigrationStatus",
      Message: "Please select the Immigration status",
    };
    return errors;
  } else if (race.trim() == "" && race.trim().length == 0) {
    myRef.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "race",
      Message: "Please select the Race",
    };
    return errors;
  } else if (gender.trim() == "" && gender.trim().length == 0) {
    myRef1.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "gender",
      Message: "Please select the Gender",
    };
    return errors;
  } else if (ethnicity.trim() == "" && ethnicity.trim().length == 0) {
    myRef1.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "ethnicity",
      Message: "Please select the Ethnicity",
    };
    return errors;
  } else if (member.trim() == "" && member.trim().length == 0) {
    myRef1.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "member",
      Message: "Please select a Patient Group",
    };
    return errors;
  } else if (occupation.trim() == "" && occupation.trim().length == 0) {
    myRef1.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "occupation",
      Message: "Occupation cannot be blank",
    };
    return errors;
  } else if (symptoms.trim() == "" && symptoms.trim().length == 0) {
    myRef2.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "symptoms",
      Message: "Please select the Symptoms experience",
    };
    return errors;
  } else if (symptoms === "Y" && !dummyarr.includes(true)) {
    myRef2.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "symptomsChecked",
      Message: "Please select one or more symptoms you are facing",
    };
    return errors;
  } else if (
    symptoms.trim() == "Y" &&
    (symptomsStarteddate === "Invalid date" || symptomsStarteddate === null)
  ) {
    myRef2.current.scrollIntoView();
    errors = {
      isError: true,
      FieldName: "symptomsCheckedDate",
      Message: "Please enter the Symptoms started date",
    };
    return errors;
  }
};

import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  InputLabel,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  Select,
} from "@mui/material";
import moment from "moment";
import { MenuItem } from "@material-ui/core";
import * as API from "../../Service/API/index.js";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const SecondStepper = ({
  details,
  newnumber,
  receiveSMS,
  setshowdiv1,
  setshowdiv2,
  setshowdiv3,
  setcurrentStep,
  setloader,
  drivingFile,
  setdrivingFile,
  vaccineFile,
  setvaccineFile,
}) => {
  //======License Image State======//
  const [drivingFilesize, setdrivingFilesize] = React.useState(false);
  const [drivingTypeError, setdrivingTypeError] = React.useState(false);
  const [drivingimgShow, setdrivingimgshow] = React.useState("");
  const [drivingError, setdrivingError] = React.useState(false);

  //======Vaccine Card Image State======//

  const [vaccineFilesize, setvaccineFilesize] = React.useState(false);
  const [vaccineTypeError, setvaccineTypeError] = React.useState(false);
  const [VaccineimgShow, setVaccineimgshow] = React.useState("");
  const [vaccineError, setvaccineError] = React.useState(false);

  //======Error States======//
  const [vaccinationError, setvaccinationError] = React.useState(false);
  const [vaccinationdateError, setvaccinationdateError] = React.useState(false);
  const [lotError, setlotError] = React.useState(false);
  const [countryError, setcountryError] = React.useState(false);
  const [stateError, setstateError] = React.useState(false);
  const [countyError, setcountyError] = React.useState(false);
  const [cityError, setcityError] = React.useState(false);
  const [locationError, setlocationError] = React.useState(false);
  const [OtherlocationError, setOtherlocationError] = React.useState(false);
  const [doseCntErr, setdoseCntErr] = React.useState(false);

  //======Second Stepper States======//
  const [doseList, setdoseList] = React.useState([
    {
      vaccineName: "",
      vaccineDate: null,
      first_notsure: false,
      lotNumber: "",
      doseCounty: "",
      doseCity: "",
      country: "",
      state: "",
      labId: 32,
      location: "Other",
      otherLabName: "",
      stateList: [],
      countyList: [],
    },
  ]);
  const [dosecountList, setdoseCountList] = React.useState([
    "First Dose",
    "Second Dose",
    "Third Dose",
    "Fourth Dose",
    "Fifth Dose",
    "Sixth Dose",
    "Seventh Dose",
    "Eighth Dose",
    "Ninth Dose",
    "Tenth Dose",
  ]);
  const [doseName, setdoseName] = React.useState("");
  const [doseCnt, setdoseCnt] = React.useState();
  //======API values store States======//
  const [VaccineTypes, setVaccineTypes] = React.useState([]);
  const [stateOrg, setstateOrg] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [countyLoader, setcountyLoader] = React.useState(false);
  const [countyLoaderIndex, setcountyLoaderIndex] = React.useState("");
  const [stateLoaderIndex, setstateLoaderIndex] = React.useState("");
  //======Get Lab======//
  const GetVaccinationConfigs = () => {
    API.GetOrg_API((res) => {
      setstateOrg(res.info);
    });
  };

  //======Get Vaccine Types======//
  const GetVaccinationNames = () => {
    API.GetVaccination_API((res) => {
      setVaccineTypes(res.info);
    });
  };

  let VaccineListFilter = [];
  if (localStorage.getItem("vaccinationStatus").trim() == "I am partially vaccinated") {
    VaccineTypes.filter((item) => {
      if (item.vaccineName !== "Janssen COVID-19 Vaccine") {
        VaccineListFilter.push(item)
      }
    })
  } else {
    VaccineTypes.filter((item) => {
      VaccineListFilter.push(item)
    })
  }
  //======Get CountryList======//
  const GetCountryList = () => {
    API.GET_BVI_COUNTRYLIST((res) => {
      setCountryList(res.info);
    });
  };

  useEffect(() => {
    GetVaccinationNames();
    GetCountryList();
    GetVaccinationConfigs();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("2ndStepData")) {
      setdoseList(JSON.parse(localStorage.getItem("2ndStepData")));
      setdoseName(JSON.parse(localStorage.getItem("2ndStepData"))[0].vaccineName)
    }
    if (drivingFile) {
      setdrivingimgshow(URL.createObjectURL(drivingFile[0]));
    }
    if (vaccineFile) {
      setVaccineimgshow(URL.createObjectURL(vaccineFile[0]));
    }
  }, [])

  //=========================================================//

  //======On Change Functionalities======//
  const DrivingHandler = (event) => {
    setdrivingTypeError(false);
    setdrivingError(false);
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setdrivingFile(event.target.files);
        setdrivingimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );
        setdrivingError(false);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setdrivingFile(event.target.files);
        setdrivingimgshow("");
        setdrivingError(false);
      }
    } else {
      setdrivingimgshow("");
      setdrivingFile();
      setdrivingError(false);
      setdrivingTypeError(true);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setdrivingError(false);
      setdrivingTypeError(false);
      setdrivingFilesize(true);
    } else {
      setdrivingFilesize(false);
    }
  };

  const VaccineHandler = (event) => {
    setvaccineTypeError(false);
    setvaccineError(false);
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );
        setvaccineError(false);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow("");
        setvaccineError(false);
      }
    } else {
      setVaccineimgshow("");
      setvaccineFile();
      setvaccineTypeError(true);
      setvaccineError(false);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setvaccineFilesize(true);
    } else {
      setvaccineFilesize(false);
    }
  };

  const doseOnchange = (e, index, field) => {
    setdoseCntErr(false);
    if (field === "vaccineDate") {
      const list = [...doseList];
      list[index]["vaccineDate"] = e === null ? null : moment(e);
      setdoseList(list);
    } else if (field === "vaccineName") {
      const { name, value } = e.target;
      const list = [...doseList];
      list[index][name] = value;
      setdoseList(list);
      if (field === "vaccineName" && index == 0) {
        setdoseName(e.target.value);
      }
    } else if (field === "lotNumber") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...doseList];
      list[index][name] = value.replaceAll("''", "'");
      setdoseList(list);
    } else if (field === "country") {
      const { name, value } = e.target;
      const list = [...doseList];
      list[index][name] = value;
      setstateLoaderIndex(`state` + index);
      setdoseList(list);
      setLoader(true);
      countryList.map((item) => {
        if (item.countryCode == value) {
          let data = {
            countryId: item.id,
          };
          API.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
              list[index]["state"] = "";
              let allEntries = Object.entries(res.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              list[index]["stateList"] = sortedEntries;
              setLoader(false);
            }
          });
        }
      });
    } else if (field === "state") {
      const { name, value } = e.target;
      const list = [...doseList];
      list[index][name] = value;
      setcountyLoaderIndex(`county` + index);
      setdoseList(list);
      setcountyLoader(true);
      let check1 = e.target.value.split(",");
      list[index]["stateList"].map((item) => {
        if (item[1].stateCode == check1[0]) {
          let data = {
            stateId: item[1].id,
          };

          API.GET_BVI_CITYLIST(data, (res) => {
            if (res.statusCode === 200) {
              list[index]["doseCounty"] = "";
              list[index]["doseCity"] = "";
              let allEntries = Object.entries(res.info.cities);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              list[index]["countyList"] = sortedEntries;
              setcountyLoader(false);
            }

          });
        }
      });
    } else if (field === "doseCounty") {
      const { name, value } = e.target;
      const list = [...doseList];
      list[index][name] = value;
      setdoseList(list);
    }
    else if (field === "doseCity") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...doseList];
      list[index][name] = value.replaceAll("''", "'");
      setdoseList(list);
    } else if (field === "location") {
      const { name, value } = e.target;
      let labidvalue = name.split("?")[0];
      let labname = name.split("?")[1];
      const lablocation = stateOrg.filter((item) => {
        return item.labId == value;
      });
      const list = [...doseList];
      list[index][labidvalue] = value;
      list[index][labname] = lablocation[0].organizationName;
      setdoseList(list);
    } else if (
      field === "first_notsure" &&
      (e.target.checked === false || e.target.checked === true)
    ) {
      const { name, checked } = e.target;
      const list = [...doseList];
      list[index][name] = checked;
      setdoseList(list);
    } else if (field === "otherLabName") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...doseList];
      list[index][name] = value.replaceAll("''", "'");
      setdoseList(list);
    }
    localStorage.setItem("2ndStepData", JSON.stringify(doseList));
  };

  //======Second Stepper Validation & API Call======//
  const AddDose = (e) => {
    e.preventDefault();
    setvaccinationError(false);
    setvaccinationdateError(false);
    setlotError(false);
    setcountryError(false);
    setstateError(false);
    setcountyError(false);
    setcityError(false);
    setlocationError(false);
    setOtherlocationError(false);
    setdoseCntErr(false);

    if (
      drivingFile === undefined ||
      drivingFile === "" ||
      drivingFile.length == 0
    ) {
      setdrivingError(true);
      setdrivingTypeError(false);
    } else if (drivingFilesize == true) {
      return 0;
    } else if (
      vaccineFile === undefined ||
      vaccineFile === "" ||
      vaccineFile.length == 0
    ) {
      setvaccineError(true);
      setvaccineTypeError(false);
    } else if (vaccineFilesize == true) {
      return 0;
    } else {
      let arr = doseList.map((item) => {
        return {
          vaccineName: item.vaccineName,
          vaccineDate: moment(item.vaccineDate).format("yyyy-MM-DD"),
          lotNumber:
            item.first_notsure === true
              ? "I'm not sure"
              : item.lotNumber.trim(),
          country: item.country,
          state: item.state,
          doseCounty: item.doseCounty,
          doseCity: item.doseCity,
          countyList: item.countyList,
          labId: item.labId,
          otherLabName: item.labId === 32 ? item.otherLabName.trim() : "",
        };
      });

      let filter_data = arr.filter(function (x) {
        if (x.vaccineName != "") {
          setvaccinationError(false);
          return true;
        } else if (x.vaccineDate != "Invalid date" || x.vaccineDate != null) {
          setvaccinationdateError(false);
          return true;
        } else if (x.lotNumber.trim() != "") {
          setlotError(false);
          return true;
        } else if (x.country != "") {
          setcountryError(false);
          return true;
        } else if (x.state != "") {
          return true;
        } else if (x.countyList.length && x.doseCounty != "") {
          return true;
        } else if (!x.countyList.length && x.doseCity != "") {
          return true;
        } else if (x.labId != "") {
          setlocationError(false);
          return true;
        } else if (x.labId !== 32 && x.otherLabName.trim() !== "") {
          setOtherlocationError(false);
          return true;
        }
      });

      let Checkfilter_data = arr.filter(function (x) {
        if (x.vaccineName == "") {
          setvaccinationError(true);
          return true;
        } else if (x.vaccineDate === "Invalid date" || x.vaccineDate === null) {
          setvaccinationdateError(true);
          return true;
        } else if (x.lotNumber.trim() == "") {
          setlotError(true);
          return true;
        } else if (x.country == "") {
          setcountryError(true);
          return true;
        } else if (x.state == "") {
          setstateError(true);
          return true;
        } else if (x.countyList.length && x.doseCounty == "") {
          setcountyError(true);
          return true;
        } else if (!x.countyList.length && x.doseCity == "") {
          setcityError(true);
          return true;
        } else if (x.labId == "") {
          setlocationError(true);
          return true;
        } else if (x.labId === 32 && x.otherLabName.trim() === "") {
          setOtherlocationError(true);
          return true;
        }
      });

      if (Checkfilter_data.length === 0) {
        setvaccinationError(false);
        setvaccinationdateError(false);
        setlotError(false);
        setcountryError(false);
        setstateError(false);
        setcountyError(false);
        setcityError(false);
        setlocationError(false);
        setOtherlocationError(false);
        setdoseList([
          ...doseList,
          {
            vaccineName: "",
            vaccineDate: null,
            first_notsure: false,
            lotNumber: "",
            country: "",
            state: "",
            doseCounty: "",
            doseCity: "",
            labId: 32,
            location: "Other",
            otherLabName: "",
            stateList: [],
            countyList: [],
          },
        ]);
      }
    }
  };
  const handleRemoveClick = (index) => {
    const list = [...doseList];
    list.splice(index, 1);
    setdoseList(list);
  };

  const next2 = (e) => {
    setvaccinationError(false);
    setvaccinationdateError(false);
    setlotError(false);
    setcountryError(false);
    setstateError(false);
    setcountyError(false);
    setcityError(false);
    setlocationError(false);
    setOtherlocationError(false);
    setdoseCntErr(false);

    if (details.vaccinationStatus.trim() != "I am not vaccinated") {
      if (
        drivingFile === undefined ||
        drivingFile === "" ||
        drivingFile.length == 0
      ) {
        setdrivingError(true);
        setdrivingTypeError(false);
      } else if (drivingFilesize == true) {
        return 0;
      } else if (
        vaccineFile === undefined ||
        vaccineFile === "" ||
        vaccineFile.length == 0
      ) {
        setvaccineError(true);
        setvaccineTypeError(false);
      } else if (vaccineFilesize == true) {
        return 0;
      } else {
        let arr = doseList.map((item) => {
          return {
            vaccinationType: item.vaccineName,
            doseDate:
              item.vaccineDate == null
                ? null
                : moment(item.vaccineDate).format("yyyy-MM-DD"),
            doseLot:
              item.first_notsure === true
                ? "I'm not sure"
                : item.lotNumber.trim(),
            doseCountry: item.country,
            doseState: item.state,
            doseCounty: item.doseCounty,
            doseCity: item.doseCity,
            countyList: item.countyList,
            doseLocation: item.location,
            doseLabId: item.labId,
            doseOtherLabName: item.labId === 32 ? item.otherLabName.trim() : "",
          };
        });

        let filter_data = arr.filter(function (x) {
          if (x.vaccinationType != "") {
            setvaccinationError(false);
            return true;
          } else if (x.doseDate != "Invalid date" || x.doseDate != null) {
            setvaccinationdateError(false);
            return true;
          } else if (x.doseLot.trim() != "") {
            setlotError(false);
            return true;
          } else if (x.doseCountry != "") {
            setcountryError(false);
            return true;
          } else if (x.doseState != "") {
            setstateError(false);
            return true;
          } else if (x.countyList.length && x.doseCounty != "") {
            setcountyError(false);
            return true;
          } else if (!x.countyList.length && x.doseCity != "") {
            setcityError(false);
            return true;
          } else if (x.doseLabId != "") {
            setlocationError(false);
            return true;
          } else if (x.doseLabId !== 32 && x.doseOtherLabName.trim() !== "") {
            setOtherlocationError(false);
            return true;
          }
        });

        const newFilterArr = filter_data.map(({ countyList, ...rest }) => {
          return rest;
        });

        let Checkfilter_data = arr.filter(function (x) {
          if (x.vaccinationType == "") {
            setvaccinationError(true);
            return true;
          } else if (x.doseDate === "Invalid date" || x.doseDate === null) {
            setvaccinationdateError(true);
            return true;
          } else if (x.doseLot.trim() == "") {
            setlotError(true);
            return true;
          } else if (x.doseCountry == "") {
            setcountryError(true);
            return true;
          } else if (x.doseState == "") {
            setstateError(true);
            return true;
          } else if (x.countyList.length && x.doseCounty == "") {
            setcountyError(true);
            return true;
          } else if (!x.countyList.length && x.doseCity == "") {
            setcityError(true);
            return true;
          } else if (x.doseLabId == "") {
            setlocationError(true);
            return true;
          } else if (x.doseLabId === 32 && x.doseOtherLabName.trim() === "") {
            setOtherlocationError(true);
            return true;
          } else if (localStorage.getItem("vaccinationStatus").trim() == "I am fully vaccinated" && doseList.length < 2 && doseName !== "Janssen COVID-19 Vaccine") {
            setdoseCntErr(true);
            return true;
          }
        });

        if (doseList.length) {
          for (let i = 0; i < doseList.length; i++) {
            if (doseList[i].vaccineName === "") {
              setvaccinationError(true);
            } else if (
              doseList[i].vaccineDate === "Invalid date" ||
              doseList[i].vaccineDate === null
            ) {
              setvaccinationdateError(true);
            } else if (
              doseList[i].first_notsure === false &&
              doseList[i].lotNumber.trim() === ""
            ) {
              setlotError(true);
            } else if (doseList[i].country === "") {
              setcountryError(true);
            } else if (doseList[i].state === "") {
              setstateError(true);
            } else if (doseList[i].countyList.length && doseList[i].doseCounty === "") {
              setcountyError(true);
            } else if (!doseList[i].countyList.length && doseList[i].doseCity === "") {
              setcityError(true);
            } else if (doseList[i].labId === "") {
              setlocationError(true);
            } else if (
              doseList[i].labId === 32 &&
              doseList[i].otherLabName.trim() === ""
            ) {
              setOtherlocationError(true);
            }
          }

          if (Checkfilter_data.length === 0) {
            let data = {
              firstName: details.firstName.trim(),
              lastName: details.lastName.trim(),
              middleName: details.middleName.trim(),
              dob: `${details.dobyear}-${details.dobmonth}-${details.dobdate}`,
              email: details.email.trim(),
              mobileNumber: "+" + newnumber,
              sendNotificationToMobile: receiveSMS,
              doses: newFilterArr,
              submittedFrom: "Travel",
            };
            setshowdiv2(false);
            setshowdiv3(true);
            setcurrentStep(2);
            window.scrollTo(0, 0);
            localStorage.setItem("2ndStepAPIData", JSON.stringify(newFilterArr));
            //     setdoseList([
            //       ...doseList,
            //       {
            //         vaccineName: "",
            //         vaccineDate: null,
            //         first_notsure: false,
            //         lotNumber: "",
            //         state: "BVI,British Virgin Islands",
            //         doseCounty: null,
            //         labId: 32,
            //         location: "Other",
            //         otherLabName: "",
            //       },
            //     ]);
            //     setvaccineFile("");
            //     setdrivingFile("");
            //   } else {
            //     setloader(false);
            //   }
            // });

            //===================//
          }
        }
      }
    }
    else {
      if (
        drivingFile === undefined ||
        drivingFile === "" ||
        drivingFile.length == 0
      ) {
        setdrivingError(true);
        setdrivingTypeError(false);
      } else if (drivingFilesize == true) {
        return 0;
      } else {
        setloader(false);
        setshowdiv2(false);
        setshowdiv3(true);
        setcurrentStep(2);
        window.scrollTo(0, 0);
      }
    }
  };

  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      next2();
    }
  };

  const back = () => {
    setshowdiv1(true);
    setshowdiv2(false);
    setcurrentStep(0);
    localStorage.setItem("fromWeb", "two");
  }
  return (
    <>
      <div className="form_body">
        <Grid container className="mt">
          <Grid item xs={12} md={12} sm={12}>
            <Typography className="mb" variant="h6">
              Submit your vaccination record
            </Typography>
            <div className="form_back">
              <div className="form_overlay">
                <Typography className="pt mx" variant="subtitle1">
                  Verification
                </Typography>
                <Typography className="mx my gray-text" variant="subtitle1">
                  To keep your data secure and confirm your identity, please
                  upload a picture of your driver's license or other government
                  issued ID.
                </Typography>
                <Grid container spacing={3} className="px py">
                  {details.vaccinationStatus === "I am not vaccinated" ? (
                    <>
                      <Grid item xs={12} md={6} sm={12}>
                        <div className="license-card">
                          {drivingFile ? (
                            <div>
                              Click 'Next' to continue or upload a new image
                              <br />
                              <span style={{ fontSize: "14px" }}>
                                Please make sure your name and date of birth are
                                visible in the image
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-inline-flex">
                          <label
                            htmlFor="file-upload"
                            className="custom-file-upload"
                          >
                            {drivingFile ? (
                              drivingFile.length > 0 ? (
                                drivingFile[0].name
                              ) : (
                                <span className="d-flex">
                                  <InsertPhotoIcon
                                    style={{ width: "20px", marginRight: "5px" }}
                                  />
                                  Upload Photo ID
                                </span>
                              )
                            ) : (
                              <span className="d-flex">
                                <InsertPhotoIcon
                                  style={{ width: "20px", marginRight: "5px" }}
                                />
                                Upload Photo ID
                              </span>
                            )}
                          </label>

                          <span className="img-label">
                            Supports png, jpeg, jpg, pdf, heif
                          </span>

                          <input
                            id="file-upload"
                            data-max-size="32154"
                            accept=".png, .jpeg, .jpg, .pdf, .heif"
                            type="file"
                            name="vaccinationFile"
                            onChange={DrivingHandler}
                          />
                        </div>
                        {drivingError ? (
                          <div className="errorText">Please upload photo ID</div>
                        ) : (
                          <div></div>
                        )}
                        {drivingFilesize ? (
                          <div className="errorText">
                            File size should not exceed more than 4 mb
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {drivingTypeError ? (
                          <div className="errorText">
                            Please upload image with required type
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        {drivingFile &&
                          drivingFile.length > 0 &&
                          drivingFile[0].type != "application/pdf" &&
                          drivingFile[0].type != "image/heif" &&
                          drivingFile[0].type !== "" ? (
                          <div className="preview-img">
                            <img
                              alt="Preview-license"
                              src={
                                drivingFile
                                  ? drivingFile.length > 0
                                    ? drivingimgShow
                                    : ""
                                  : ""
                              }
                            ></img>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6} sm={12}>
                        <div className="license-card">
                          {drivingFile ? (
                            <div>
                              Click 'Next' to continue or upload a new image
                              <br />
                              <span style={{ fontSize: "14px" }}>
                                Please make sure your name and date of birth are
                                visible in the image
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-inline-flex">
                          <label
                            htmlFor="file-upload"
                            className="custom-file-upload"
                          >
                            {drivingFile ? (
                              drivingFile.length > 0 ? (
                                drivingFile[0].name
                              ) : (
                                <span className="d-flex">
                                  <InsertPhotoIcon
                                    style={{
                                      width: "20px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Upload Photo ID
                                </span>
                              )
                            ) : (
                              <span className="d-flex">
                                <InsertPhotoIcon
                                  style={{ width: "20px", marginRight: "5px" }}
                                />
                                Upload Photo ID
                              </span>
                            )}
                          </label>

                          <span className="img-label">
                            Supports png, jpeg, jpg, pdf, heif
                          </span>

                          <input
                            id="file-upload"
                            data-max-size="32154"
                            accept=".png, .jpeg, .jpg, .pdf, .heif"
                            type="file"
                            name="vaccinationFile"
                            onChange={DrivingHandler}
                          />
                        </div>
                        {drivingError ? (
                          <div className="errorText">
                            Please upload photo ID
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {drivingFilesize ? (
                          <div className="errorText">
                            File size should not exceed more than 4 mb
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {drivingTypeError ? (
                          <div className="errorText">
                            Please upload image with required type
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        {drivingFile &&
                          drivingFile.length > 0 &&
                          drivingFile[0].type != "application/pdf" &&
                          drivingFile[0].type != "image/heif" &&
                          drivingFile[0].type !== "" ? (
                          <div className="preview-img">
                            <img
                              alt="Preview-license"
                              src={
                                drivingFile
                                  ? drivingFile.length > 0
                                    ? drivingimgShow
                                    : ""
                                  : ""
                              }
                            ></img>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={12} sm={12} className="gray-text">
                        To confirm your vaccination status, please upload a
                        picture of your vaccination record or your SMART Health
                        card.
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <div className="vaccination-card">
                          {vaccineFile ? (
                            <div>
                              Click 'Next' to continue or upload a different
                              image
                              <br />
                              <span style={{ fontSize: "14px" }}>
                                Please make sure your name and date of birth are
                                visible in the image
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-inline-flex">
                          <label
                            htmlFor="file-upload1"
                            className="custom-file-upload"
                          >
                            {vaccineFile ? (
                              vaccineFile.length > 0 ? (
                                vaccineFile[0].name
                              ) : (
                                <span className="d-flex">
                                  <InsertPhotoIcon
                                    style={{
                                      width: "20px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Upload vaccination record
                                </span>
                              )
                            ) : (
                              <span
                                style={{ width: "100%" }}
                                className="d-flex"
                              >
                                <InsertPhotoIcon
                                  style={{ width: "20px", marginRight: "5px" }}
                                />
                                Upload vaccination record
                              </span>
                            )}
                          </label>
                          <span className="img-label">
                            Supports png, jpeg, jpg, pdf, heif
                          </span>
                          <input
                            id="file-upload1"
                            accept=".png, .jpeg, .jpg, .pdf , .heif"
                            data-max-size="32154"
                            type="file"
                            name="licenseFile"
                            onChange={VaccineHandler}
                          />
                        </div>
                        {vaccineError ? (
                          <div className="errorText">
                            Please upload vaccine record
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {vaccineFilesize ? (
                          <div className="errorText">
                            File size should not exceed more than 4 mb
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {vaccineTypeError ? (
                          <div className="errorText">
                            Please upload image with required type
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        {vaccineFile &&
                          vaccineFile.length > 0 &&
                          vaccineFile[0].type != "application/pdf" &&
                          vaccineFile[0].type != "image/heif" &&
                          vaccineFile[0].type !== "" ? (
                          <div className="preview-img">
                            <img
                              alt="VaccinationPreviewImg"
                              src={
                                vaccineFile
                                  ? vaccineFile.length > 0
                                    ? VaccineimgShow
                                    : ""
                                  : ""
                              }
                            ></img>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
                {details.vaccinationStatus !== "I am not vaccinated" ? (
                  <>
                    {doseList.map((dose, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Typography className="pt mx" variant="subtitle1">
                            <span style={{ margin: "0 5px" }}>
                              {dosecountList[i]}
                            </span>
                          </Typography>
                          <Grid
                            container
                            spacing={3}
                            style={{ paddingTop: "10px" }}
                            className="px"
                          >
                            <Grid item xs={12} md={12} sm={12}>
                              <FormControl
                                className="form-dropdown form-vaccine-type"
                                fullWidth
                                variant="standard"
                              >
                                <InputLabel>Vaccine name *</InputLabel>
                                <Select
                                  name="vaccineName"
                                  value={dose.vaccineName}
                                  onChange={(e) =>
                                    doseOnchange(e, i, "vaccineName")
                                  }
                                  required
                                >
                                  {VaccineListFilter.map((item) => (
                                    <MenuItem value={item.vaccineName}>
                                      {item.vaccineName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {vaccinationError === true &&
                                dose.vaccineName === "" ? (
                                <div className="errorText">
                                  Please select Vaccine name
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6} sm={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  style={{ width: "100%" }}
                                  clearable
                                  value={dose.vaccineDate}
                                  className="dateSelection"
                                  onChange={(e) =>
                                    doseOnchange(e, i, "vaccineDate")
                                  }
                                  format="MM/dd/yyyy"
                                  label={`${dosecountList[i]} date (MM/DD/YYYY)`}
                                  maxDate={new Date()}
                                  required
                                  autoOk
                                />
                              </MuiPickersUtilsProvider>
                              {vaccinationdateError === true &&
                                dose.vaccineDate === null ? (
                                <div className="errorText">
                                  Please enter the Vaccine date
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <TextField
                                className="form_textfield mt"
                                variant="standard"
                                placeholder="Lot # *"
                                autoComplete="off"
                                disabled={
                                  dose.first_notsure == true ? true : false
                                }
                                value={
                                  dose.first_notsure === false
                                    ? dose.lotNumber
                                    : dose.first_notsure === true
                                      ? "I'm not sure"
                                      : dose.lotNumber
                                }
                                onChange={(e) =>
                                  doseOnchange(e, i, "lotNumber")
                                }
                                onKeyUp={EnterKeynext}
                                required
                                name="lotNumber"
                                inputProps={{ maxLength: 40 }}
                              />
                              {lotError === true &&
                                dose.lotNumber.trim() === "" &&
                                dose.first_notsure === false ? (
                                <div className="errorText">
                                  Please enter LOT number
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                className="form-not-sure mt "
                                control={
                                  <Checkbox
                                    style={{ paddingRight: "10px" }}
                                    onChange={(e) =>
                                      doseOnchange(e, i, "first_notsure")
                                    }
                                    name="first_notsure"
                                    checked={dose.first_notsure}
                                  />
                                }
                                label=" I'm not sure"
                              />
                            </Grid>
                            <Grid item xs={12} md={6} sm={12}>
                              <FormControl
                                className="form-dropdown"
                                fullWidth
                                variant="standard"
                              >
                                <InputLabel>Country*</InputLabel>
                                <Select
                                  name="country"
                                  value={dose.country}
                                  onChange={(e) =>
                                    doseOnchange(e, i, "country")
                                  }
                                  required
                                >
                                  {countryList.map((item) => (
                                    <MenuItem value={item.countryCode}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {countryError === true && dose.country === "" ? (
                                <div className="errorText">
                                  Please select the Country
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                            {`state` + i == stateLoaderIndex && loader == true ? (
                              <div className="stateLoader">
                                <div class="loader1">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>
                            ) : (
                              <Grid item xs={12} md={6} sm={12}>
                                <FormControl
                                  className="form-dropdown"
                                  fullWidth
                                  variant="standard"
                                >
                                  <InputLabel>State/Territory*</InputLabel>
                                  <Select
                                    name="state"
                                    value={dose.state}
                                    onChange={(e) =>
                                      doseOnchange(e, i, "state")
                                    }
                                    required
                                  >
                                    {dose.stateList.map((item) => (
                                      <MenuItem
                                        value={
                                          item[1].stateCode + "," + item[0]
                                        }
                                      >
                                        {item[0]}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {stateError === true && dose.state === "" ? (
                                  <div className="errorText">
                                    Please select the State
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                            )}
                            {`county` + i == countyLoaderIndex && countyLoader == true ? (
                              <div className="stateLoader">
                                <div class="loader1">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>

                            ) : (<>
                              {dose.countyList.length ? <><Grid item xs={12} md={6} sm={12}>
                                <FormControl
                                  className="form-dropdown"
                                  fullWidth
                                  variant="standard"
                                >
                                  <InputLabel>County/District*</InputLabel>
                                  <Select
                                    name="doseCounty"
                                    value={dose.doseCounty}
                                    onChange={(e) => doseOnchange(e, i, "doseCounty")}
                                    required
                                  >
                                    {dose.countyList.map((item) => (
                                      <MenuItem value={item[0]}>{item[0]}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {countyError === true && dose.doseCounty === "" ? (
                                  <div className="errorText">
                                    Please select the County
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid></> : <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  className="form_textfield"
                                  label="City/Estate*"
                                  variant="standard"
                                  name="doseCity"
                                  inputProps={{ maxLength: 40, autoComplete: "none" }}
                                  value={dose.doseCity}
                                  onChange={(e) => doseOnchange(e, i, "doseCity")}
                                />
                                {cityError === true && dose.doseCity === "" ? (
                                  <div className="errorText">
                                    Please enter the City
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>}</>
                            )
                            }
                            {/* <Grid item xs={12} md={6} sm={12}>
                          <FormControl
                            className="form-dropdown"
                            fullWidth
                            variant="standard"
                          >
                            <InputLabel
                              style={{
                                paddingBottom: "0.3em",
                                width: "100%",
                              }}
                            >
                              Dose location *
                            </InputLabel>
                            <Select
                              required
                              name="labId?location"
                              value={dose.labId}
                              onChange={(e) => doseOnchange(e, i, "location")}
                            >
                              {stateOrg.map((item) => (
                                <MenuItem value={item.labId}>
                                  {item.organizationName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {locationError === true && dose.location === "" ? (
                            <div className="errorText">
                              Please select dose location
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </Grid> */}
                            {dose.labId === 32 && (
                              <Grid
                                item
                                xs={12}
                                md={6}
                                sm={12}
                                style={{ marginTop: "4px" }}
                              >
                                <TextField
                                  value={dose.otherLabName}
                                  variant="standard"
                                  className="form_textfield"
                                  name="otherLabName"
                                  label="Please specify vaccination site *"
                                  onKeyUp={EnterKeynext}
                                  onChange={(e) =>
                                    doseOnchange(e, i, "otherLabName")
                                  }
                                  inputProps={{
                                    maxLength: 30,
                                    autoComplete: "off",
                                  }}
                                  autoComplete="off"
                                />

                                {OtherlocationError === true &&
                                  dose.otherLabName.trim() === "" ? (
                                  <div className="errorText">
                                    Please enter the site name
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                            )}{" "}
                            <Grid item xs={12} md={12} sm={12}>
                              <div className="removeDose">
                                {i ==
                                  (doseList.length === 1
                                    ? doseList.length
                                    : doseList.length - 1) && (
                                    <span
                                      style={{
                                        backgroundColor: "#f07167",
                                        padding: "3px 8px",
                                        borderRadius: "0.4rem",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      <DeleteIcon
                                        style={{
                                          width: "20px",
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                      Remove
                                    </span>
                                  )}
                              </div>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                <Grid container spacing={3} className="px py">
                  {(details.vaccinationStatus !== "I am not vaccinated" && details.vaccinationStatus !== "I am partially vaccinated") ? (
                    <>
                      <Grid item xs={12} md={12} sm={12}>
                        <div className="d-flex">
                          <div>
                            <button className="btn" onClick={back}>
                              <ArrowBackIosNewIcon
                                style={{ width: "17px", marginRight: "5px" }}
                              />
                              Back
                            </button>
                          </div>
                          <div>
                            <button className="btn" onClick={AddDose}>
                              <AddBoxIcon
                                style={{ width: "20px", marginRight: "5px" }}
                              />
                              Add Dose
                            </button>
                          </div>
                          <div>
                            <button className="btn" onClick={next2}>
                              Next
                              <ArrowForwardIosIcon
                                style={{ width: "17px", marginLeft: "5px" }}
                              />
                            </button>
                          </div>
                        </div>
                        {doseCntErr ? (
                          <div className="errorText1 mt">
                            The selected vaccine requires two doses to be considered fully vaccinated. Please enter second dose information or if you have only received one dose, select “Partially Vaccinated” on the previous page.
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={12} sm={12}>
                        <div className="d-flex">
                          <div>
                            <button className="btn" onClick={back}>
                              <ArrowBackIosNewIcon
                                style={{ width: "17px", marginRight: "5px" }}
                              />
                              Back
                            </button>
                          </div>
                          <div>
                            <button className="btn" onClick={next2}>
                              Next
                              <ArrowForwardIosIcon
                                style={{ width: "17px", marginLeft: "5px" }}
                              />
                            </button>
                          </div>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SecondStepper;


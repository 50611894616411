import React, { useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { Grid, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as API from "../../Service/API/index.js";
import { toast } from "react-toastify";
import PaymentImage from "../../Assets/PaymentImage.png";
import DownloadIcon from "../../Assets/download.png";
import "./PaymentForm.css";

const CheckoutForm = ({ stripe, elements, succeeded, setSucceeded, email }) => {
  const [amount, setAmount] = useState((localStorage.getItem("vaccinationStatus") == "I am not vaccinated" || localStorage.getItem("vaccinationStatus") == "I am partially vaccinated")
   ? parseInt(process.env.REACT_APP_TEST_AMOUNT)
   : parseInt(process.env.REACT_APP_CHARGE_AMOUNT));
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paymentStatus, setpaymentStatus] = useState("");
  const [getQRcode, setQrcode] = React.useState(
    "https://staging-hummingbird-health.s3.amazonaws.com/humming-bird-health/test/staging/patient-qr/patient-7276-test-955/test-record-G8LXRX-1633330737.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211004T065857Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA5NXHKJDMN3YC3BUT%2F20211004%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=a753ba2e364d6c353b051cc1a10c766475035ed3c84366b87f899aed51f30caa"
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    console.log(elements.getElement(CardElement));
    if (result.error) {
      setError(`Payment failed: ${result.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      console.log("=======API-Call======");
      let data = {
        testId: localStorage.getItem("testId"),
        description: "BVI Travel portal payment",
        amount: amount,
        stripeEmail: email,
        stripeToken: result.paymentMethod.id,
        accountToTransfer: process.env.REACT_APP_ACCOUNT_TRANSFER_CODE,
        formId: localStorage.getItem("formId") != null ? localStorage.getItem("formId") : "",
      };

      API.PAYMENT_API_URL(data, (res) => {
        if (res.statusCode === 200) {
          setSucceeded(true);
          setProcessing(false);
          setpaymentStatus("Payment Success");
        } else {
          setSucceeded(true);
          setProcessing(false);
          toast.error(res.errorMessage);
          setpaymentStatus("Payment Failed");
        }
      });
    }
  };

  const renderSuccess = () => {
    return (
      <>
        <div className="mobile-phone">
          <div className="sr-field-success message"></div>
          <div
            className={
              paymentStatus == "Payment Success"
                ? "paymentSuccessStatus"
                : paymentStatus == "Payment Failed"
                ? "paymentFailedStatus"
                : ""
            }
          >
            {paymentStatus}
          </div>
          <div className="d-flex">
            <img
              src={localStorage.getItem("qrCodeUrl")}
              className="QR_code_image"
              alt="QRcodeImg"
            />
          </div>
          <div className="d-flex">
            <button className="download-btn">
              <a
                style={{ textDecoration: "none", color: "#FFF" }}
                href={localStorage.getItem("qrCodePdfUrl")}
                target="_blank"
              >
                <img src={DownloadIcon} alt="downloadImg" />
                <span style={{ paddingLeft: "5px" }}>Download</span>
              </a>
            </button>
          </div>
          <Grid container style={{ marginTop: "10px" }}>
            <Grid item xs={12} md={12} sm={12}>
              <div
                className="d-flex ml"
                style={{ justifyContent: "flex-start" }}
              >
                <Typography variant="body1" className="title_color">
                  Confirmation Code:
                </Typography>
                <p className="title_value">
                  {localStorage.getItem("confirmationCode")}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <div
                className="d-flex ml"
                style={{ justifyContent: "flex-start" }}
              >
                <Typography variant="body1" className="title_color">
                  Name:
                </Typography>
                <p className="title_value">
                  {localStorage.getItem("Fname")}{" "}
                  {localStorage.getItem("Lname")}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <div
                className="d-flex ml"
                style={{ justifyContent: "flex-start" }}
              >
                <Typography variant="body1" className="title_color">
                  DOB(MM/DD/YYYY):
                </Typography>
                <p className="title_value">{localStorage.getItem("DOB")}</p>
              </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <div
                className="d-flex ml"
                style={{ justifyContent: "flex-start" }}
              >
                <Typography variant="body1" className="title_color">
                  Lab Site:
                </Typography>
                <p className="title_value">
                  {localStorage.getItem("labSiteName")}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <div
                className="d-flex ml"
                style={{ justifyContent: "flex-start" }}
              >
                <Typography variant="body1" className="title_color">
                  Appointment Created Date and Time
                  <span style={{ fontSize: "14px" }}>(AST)</span>:
                </Typography>
                <p className="title_value">
                  {localStorage.getItem("createdDate")}{" "}
                  {localStorage.getItem("createdTime")}
                </p>
              </div>
            </Grid>

            {paymentStatus == "Payment Failed" && (
              <Grid item xs={12} md={12} sm={12}>
                <p className="note_text">
                  Note: Your payment of ${amount} was NOT able to be processed.
                  Please plan to pay with cash or credit card at your test site.
                </p>
              </Grid>
            )}
          </Grid>
        </div>
      </>
    );
  };

  const renderForm = () => {
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Poppins", sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    return (
      <form onSubmit={handleSubmit}>
        <Typography className="pt" variant="h6">
          {"$"}
          {amount.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}
        </Typography>
        <Typography className="pt" variant="subtitle1">
          Enter payment details
        </Typography>
        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              style={{ color: "#00afb9" }}
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="off"
              value={email}
              disabled
              className="sr-input"
            />
          </div>
          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <button className="paybtn mt" disabled={processing || !stripe}>
            {processing ? "Processing…" : "Submit Payment"}
          </button>
        </div>

        {error && <div className="message sr-field-error">{error}</div>}
      </form>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
};

export default function FourthStepper({
  setshowdiv1,
  setshowdiv4,
  setDetails,
  setNumber,
  email,
  setloader,
  setcurrentStep,
}) {
  //======Stripe States======//
  const API_Key = process.env.REACT_APP_PUBLIC_API_KEY;
  const stripePromise = loadStripe(API_Key);

  const [succeeded, setSucceeded] = useState(false);

  const back = () => {
    localStorage.clear();
    setDetails({
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      dobmonth: "",
      dobdate: "",
      dobyear: "",
      arrivingLab: "",
    });
    setNumber("");
    setshowdiv1(true);
    setshowdiv4(false);
    setcurrentStep(0);
  };

  return (
    <>
      <div className="form_body">
        <Grid container className="mt">
          <Grid item xs={12} md={12} sm={12}>
            {/* <Typography className="mb" variant="h6">
              Submit your vaccination record
            </Typography> */}
            <div className="form_back mt">
              <div className="form_overlay">
                <Typography className="pt mx" variant="subtitle1">
                  Submit Payment
                </Typography>
                <Grid container spacing={3} className="px py">
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sm={12}
                    className="d-flex"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="payment_svg_bg">
                      <img
                        style={{ width: "100%" }}
                        src={PaymentImage}
                        alt="paymentImage"
                      />
                    </div>
                    {succeeded && (
                      <button className="btn1 my mx" onClick={back}>
                        Go back
                      </button>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={7}
                    sm={12}
                    style={{ paddingTop: "0px" }}
                  >
                    <Elements stripe={stripePromise}>
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <CheckoutForm
                            stripe={stripe}
                            elements={elements}
                            succeeded={succeeded}
                            email={email}
                            setSucceeded={setSucceeded}
                          />
                        )}
                      </ElementsConsumer>
                    </Elements>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

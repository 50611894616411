import React, { useState, useRef, useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import {
  Grid,
  Typography,
  InputLabel,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import moment from "moment";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PhoneInput from "react-phone-input-2";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as API from "../../Service/API/index.js";
import { ValidateData } from "./Validation1.js";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const ThirdStepper = ({
  details,
  newnumber,
  receiveSMS,
  setshowdiv2,
  setshowdiv3,
  setloader,
  setshowdiv4,
  setcurrentStep,
  drivingFile,
  vaccineFile,
  setdrivingFile,
  setvaccineFile,
  dialCode,
}) => {
  const initialFormData = Object.freeze({
    //======General Information Field values======//
    addressOne: "",
    addressTwo: "",
    city: "",
    zipCode: "",
    immigrationStatus: "",
    county: "",
    race: "",
    gender: "",
    ethnicity: "",
    member: "",
    occupation: "",
    pregnancy: "U",
  });

  //======Symptoms States======//
  const SymptomsList = Object({
    Shortness_of_breath: false,
    Cough: false,
    Chills: false,
    Fever: false,
    Difficulty_breathing: false,
    Headache: false,
    Fatigue: false,
    Diarrhea: false,
    Vomiting: false,
    Sore_Throat: false,
    Nausea: false,
    Loss_of_taste: false,
    Body_and_or_muscle_aches: false,
    Congestion_and_or_runny_nose: false,
  });

  //======Error States======//
  const [errorState, setErrorstate] = useState({
    isError: false,
    FieldName: "",
    Message: "",
  });

  //======Form States======//
  const [formstate, setformState] = React.useState(initialFormData);
  const [symptomsChecked, setsymptomsChecked] = React.useState(SymptomsList);
  const [stateSelect, setstateSelect] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [countyValue, setcountyValue] = React.useState([]);
  const [ethnicityValues, setethnicityValues] = React.useState([]);
  const [raceValues, setraceValues] = React.useState([]);
  const [symptoms, setsymptoms] = React.useState("");
  const [symptomsStarteddate, setsymptomsStarteddate] = React.useState(null);
  const myRef = useRef(null);
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);

  //======Get Dropdown Deails======//
  const GetDropdownDetails = () => {
    API.Test_Form_Detail_API((res) => {
      setethnicityValues(res.info.ethinicitys);
      setraceValues(res.info.races);
    });
  };

  //======Get CountryList======//
  const GetCountryList = () => {
    API.GET_BVI_COUNTRYLIST((res) => {
      setCountryList(res.info);
    });
  };

  useEffect(() => {
    GetDropdownDetails();
    GetCountryList();
    if (localStorage.getItem("3rdStepData")) {
      setformState(JSON.parse(localStorage.getItem("3rdStepData")))
      setCountry(localStorage.getItem("3rdStepCountry"));
      setState(localStorage.getItem("3rdStepState"));
      setstateSelect(JSON.parse(localStorage.getItem("3rdStepStateList")) !== null ? JSON.parse(localStorage.getItem("3rdStepStateList")) : []);
      setcountyValue(JSON.parse(localStorage.getItem("3rdStepCountyList")) !== null ? JSON.parse(localStorage.getItem("3rdStepCountyList")) : []);
      setsymptoms(localStorage.getItem("formDataSymptoms") !== null ? localStorage.getItem("formDataSymptoms") : "");
      setsymptomsChecked(JSON.parse(localStorage.getItem("formDataSymptomsChecked")) !== null ? JSON.parse(localStorage.getItem("formDataSymptomsChecked")) : symptomsChecked);
      setsymptomsStarteddate(localStorage.getItem("formDataSymptomsDate"));
    }
  }, []);

  //=========================================================//

  //======On Change Functionalities======//

  const handleChangeForm = (event) => {
    if (event.target.name == "addressOne" || event.target.name == "addressTwo"
      || event.target.name == "city" || event.target.name == "zipCode" ||
      event.target.name == "occupation") {
      const name = event.target.name;
      const value = event.target.value.replaceAll('"', "'");
      setformState({
        ...formstate,
        [name]: value.replaceAll("''", "'"),
      });
    } else {
      const name = event.target.name;
      setformState({
        ...formstate,
        [name]: event.target.value,
      });
    }

  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    setcountyValue([]);
    countryList.map((item) => {
      if (item.countryCode == event.target.value) {
        let data = {
          countryId: item.id,
        };

        API.GET_BVI_STATELIST(data, (res) => {
          if (res.statusCode === 200) {
            setState("");
            setformState({
              ...formstate,
              ["county"]: "",
              ["city"]: "",
            });
            let allEntries = Object.entries(res.info.states);
            let sortedEntries = allEntries.sort((a, b) =>
              a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            setstateSelect(sortedEntries);
          }
        });
      }
    });
  };

  const handleChangeState = (event) => {
    setState(event.target.value);
    localStorage.setItem("3rdStepState", event.target.value);
    setformState({
      ...formstate,
      ["county"]: "",
      ["city"]: "",
    });
    let check1 = event.target.value.split(",");
    stateSelect.map((item) => {
      if (item[1].stateCode == check1[0]) {
        let data = {
          stateId: item[1].id,
        };
        API.GET_BVI_CITYLIST(data, (res) => {
          let allEntries = Object.entries(res.info.cities);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setcountyValue(sortedEntries);
          localStorage.setItem("3rdStepCountyList", JSON.stringify(sortedEntries));
        });
      }
    });
  };

  const handleChangeSymptoms = (event) => {
    setsymptoms(event.target.value);
    localStorage.setItem("formDataSymptoms", event.target.value);
    if (event.target.value === "N") {
      setsymptomsStarteddate(null);
      setsymptomsChecked(SymptomsList);
    }
  };

  const handleChangeSymptomsChecks = (event) => {
    setsymptomsChecked({
      ...symptomsChecked,
      [event.target.name]: event.target.checked,
    });
    
  };

  const handleChangeSymptomsStart = (date) => {
    setsymptomsStarteddate(date);
    localStorage.setItem("formDataSymptomsDate", date);
  };

  //======Third Stepper Validation & API Call======//
  const next3 = () => {
    let Formdata = {
      ...formstate,
      symptoms,
      symptomsChecked,
      symptomsStarteddate,
      state,
      country,
      countyValue,
      myRef,
      myRef1,
      myRef2,
    };
    let value = ValidateData(Formdata);
    if (value) {
      setErrorstate({
        isError: value.isError,
        FieldName: value.FieldName,
        Message: value.Message,
      });
    } else {
      setErrorstate({
        isError: false,
        FieldName: "",
        Message: "",
      });
      alertbtn(details.firstName.trim(), details.lastName.trim(), `${details.dobyear}-${details.dobmonth}-${details.dobdate}`, newnumber, details.email.trim());
    }
  };

  //===Pop Up modal functionalities===//
  const alertbtn = (Fname, Lname, DOB, Phone, Email) => {
    confirmAlert({
      title: 'Please confirm your details',
      message: `Please confirm that your information is correct. Your name and date of birth should be exactly as it appears on your photo ID. This information will be on your result report. Also confirm your phone number and email are valid to receive your result notification.`,
      childrenElement: () => <div style={{ display: "flex", flexDirection: "column", margin: "5px 0px" }}>
        <div style={{ margin: "5px 0px" }}>First Name:<span className="Status_name">{Fname}</span></div>
        <div style={{ margin: "5px 0px" }}>Last Name:<span className="Status_name">{Lname}</span></div>
        <div style={{ margin: "5px 0px" }}>DOB (MMM DD, YYYY):<span className="Status_name">{moment(DOB).format("MMM DD, YYYY")}</span></div>
        <div className="modalPhoneNumber" style={{ margin: "-3px 0px", display: "flex", alignItems: "center" }}><div>Phone:</div>
          <PhoneInput
            country={"us"}
            disabled={true}
            required
            name="mobileNumber"
            disableDropdown={true}
            value={Phone}
          /></div>
        <div style={{ margin: "5px 0px" }}>Email:<span className="Status_name">{Email ? Email : "N/A"}</span></div>
      </div>,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => confirmfun()
        },
        {
          label: 'Cancel',
          onClick: () => cancelfun()
        }
      ],
    })
  };

  const confirmfun = () => {

    setloader(true);
    let splitstate = state.split(",");
    const formData = new FormData();
    const formData1 = new FormData();
    let data = {
      labId: details.arrivingLab,
      firstName: details.firstName.trim(),
      lastName: details.lastName.trim(),
      middleName: details.middleName.trim(),
      dob: `${details.dobyear}-${details.dobmonth}-${details.dobdate}`,
      email: details.email.trim(),
      mobileNumber: "+" + newnumber,
      sendMessageToMobile: receiveSMS,
      addressOne: formstate.addressOne.trim(),
      addressTwo: formstate.addressTwo.trim(),
      city:
        formstate.city.trim() == ""
          ? formstate.county
          : formstate.city.trim(),
      country: country.trim(),
      state: splitstate[0],
      zipCode: formstate.zipCode.trim(),
      county: formstate.county.trim(),
      immigrationStatus: formstate.immigrationStatus,
      race: formstate.race,
      gender: formstate.gender,
      ethnicity: formstate.ethnicity,
      prioritizedGroup: formstate.member,
      occupation: formstate.occupation.trim(),
      pregnant: formstate.pregnancy,
      symptomatic: symptoms,
      symptomaticDate:
        symptoms == "Y"
          ? moment(symptomsStarteddate).format("yyyy-MM-DD")
          : null,
      symptoms:
        Object.keys(symptomsChecked).filter(
          (e) => symptomsChecked[e] === true
        ).length == 0
          ? null
          : Object.keys(symptomsChecked).filter(
            (e) => symptomsChecked[e] === true
          ),
      vaccinationStatus: details.vaccinationStatus,
      testType: details.vaccinationStatus.trim() == "I am fully vaccinated"
        ? "Covid-19: BinaxNow"
        : "RIDA GENE SARS-CoV-2 RT-PCR test",
    };
    let data1 = {
      ...data,
      phoneCode: dialCode,
      doses: JSON.parse(localStorage.getItem("2ndStepAPIData")),
      submittedFrom: "Travel",
      sendNotificationToMobile: receiveSMS,
      fromWeb: true,
    }
    if (details.vaccinationStatus.trim() == "I am not vaccinated") {
      console.log("===========Not Vaccinated API Call===========");
      console.log("data", data);
      formData.append("formData", JSON.stringify(data));
      formData.append("licenseFile", drivingFile[0]);
      API.VAX_NOT_DETAILS(formData, (res) => {
        if (res.statusCode === 200) {
          localStorage.setItem("confirmationCode", res.info.confirmationCode);
          localStorage.setItem("qrCodeUrl", res.info.qrCodeUrl);
          localStorage.setItem("qrCodePdfUrl", res.info.qrCodePdfUrl);
          localStorage.setItem("testId", res.info.testId);
          localStorage.setItem("createdDate", res.info.createdDate);
          localStorage.setItem("createdTime", res.info.createdTime);
          localStorage.setItem("labSiteName", res.info.labSiteName);
          setloader(false);
          setshowdiv2(false);
          setshowdiv3(false);
          setshowdiv4(true);
          setcurrentStep(3);
          window.scrollTo(0, 0);
          setformState(initialFormData);
          setsymptomsChecked(SymptomsList);
          setState("");
          setCountry("");
          setsymptoms("");
          setdrivingFile("");
          setvaccineFile("");
          localStorage.removeItem("3rdStepData");
          localStorage.removeItem("3rdStepCountry");
          localStorage.removeItem("3rdStepStateList");
          localStorage.removeItem("3rdStepState");
          localStorage.removeItem("3rdStepCountyList");
          localStorage.removeItem("formDataSymptoms");
          localStorage.removeItem("formDataSymptomsChecked");
          localStorage.removeItem("formDataSymptomsDate");
          localStorage.removeItem("2ndStepData");
          localStorage.removeItem("2ndStepAPIData");
          localStorage.removeItem("1stStepperData");
          localStorage.removeItem("1stStepperNumber");
          localStorage.removeItem("1stStepperDialcode");
          localStorage.removeItem("1stStepperTextSMS");   
        } else {
          setloader(false);
        }
      });
    } else {
      console.log("===========Partially & Fully Vaccinated API Call===========");
      console.log("data1", data1);
      formData1.append("formData", JSON.stringify(data1));
      formData1.append("licenseFile", drivingFile[0]);
      formData1.append("cardFile", vaccineFile[0]);
      API.VAX_DETAILS_UPDATE(formData1, (res) => {
        if (res.statusCode === 200) {
          localStorage.setItem("confirmationCode", res.info.confirmationCode);
          localStorage.setItem("qrCodeUrl", res.info.qrCodeUrl);
          localStorage.setItem("qrCodePdfUrl", res.info.qrCodePdfUrl);
          localStorage.setItem("testId", res.info.testId);
          localStorage.setItem("createdDate", res.info.createdDate);
          localStorage.setItem("createdTime", res.info.createdTime);
          localStorage.setItem("labSiteName", res.info.labSiteName);
          localStorage.setItem("formId", res.info.formId);
          localStorage.setItem("patientId", res.info.patientId);
          setloader(false);
          setshowdiv2(false);
          setshowdiv3(false);
          setshowdiv4(true);
          setcurrentStep(3);
          window.scrollTo(0, 0);
          setformState(initialFormData);
          setsymptomsChecked(SymptomsList);
          setState("");
          setCountry("");
          setsymptoms("");
          setdrivingFile("");
          setvaccineFile("");
          localStorage.removeItem("3rdStepData");
          localStorage.removeItem("3rdStepCountry");
          localStorage.removeItem("3rdStepStateList");
          localStorage.removeItem("3rdStepState");
          localStorage.removeItem("3rdStepCountyList");
          localStorage.removeItem("formDataSymptoms");
          localStorage.removeItem("formDataSymptomsChecked");
          localStorage.removeItem("formDataSymptomsDate");
          localStorage.removeItem("2ndStepData");
          localStorage.removeItem("2ndStepAPIData");
          localStorage.removeItem("1stStepperData");
          localStorage.removeItem("1stStepperNumber");
          localStorage.removeItem("1stStepperDialcode");
          localStorage.removeItem("1stStepperTextSMS");         
        } else {
          setloader(false);
        }
      });
    }


  }

  const cancelfun = () => {
    console.log("Clicked Cancel to submit");
  }
  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      next3();
    }
  };

  const back = () => {
    localStorage.setItem("3rdStepData", JSON.stringify(formstate));
    localStorage.setItem("3rdStepCountry", country);
    localStorage.setItem("3rdStepStateList", JSON.stringify(stateSelect));
    localStorage.setItem("formDataSymptomsChecked", JSON.stringify(symptomsChecked));
    setshowdiv2(true);
    setshowdiv3(false);
    setcurrentStep(1);
  }

  const { isError, FieldName, Message } = errorState;
  return (
    <>
      <div className="form_body">
        <Grid container className="mt">
          <Grid item xs={12} md={12} sm={12}>
            <Typography className="mb" variant="h6">
              Submit your vaccination record
            </Typography>
            <div className="form_back">
              <div className="form_overlay">
                <Typography className="pt mx" variant="subtitle1">
                  General Information
                </Typography>
                <Grid container spacing={4} className="px py mb" ref={myRef}>
                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      className="form_textfield"
                      label="Address 1*"
                      variant="standard"
                      name="addressOne"
                      inputProps={{ maxLength: 40, autoComplete: "none" }}
                      value={formstate.addressOne}
                      onKeyUp={EnterKeynext}
                      onChange={handleChangeForm}
                      error={Boolean(isError && FieldName === "addressOne")}
                      helperText={FieldName === "addressOne" && Message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      className="form_textfield"
                      label="Address 2"
                      variant="standard"
                      name="addressTwo"
                      inputProps={{ maxLength: 40, autoComplete: "none" }}
                      value={formstate.addressTwo}
                      onChange={handleChangeForm}
                      onKeyUp={EnterKeynext}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>Country*</InputLabel>
                      <Select
                        name="country"
                        value={country}
                        onChange={handleChangeCountry}
                        required
                      >
                        {countryList.map((item) => (
                          <MenuItem value={item.countryCode}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {FieldName === "country" ? (
                      <FormHelperText className="errorText">
                        {Message}
                      </FormHelperText>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                      disabled={!stateSelect.length}
                    >
                      <InputLabel>State/Territory*</InputLabel>
                      <Select
                        name="state"
                        value={state}
                        onChange={handleChangeState}
                        required
                      >
                        {stateSelect.map((item) => (
                          <MenuItem value={item[1].stateCode + "," + item[0]}>
                            {item[0]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {FieldName === "state" ? (
                      <FormHelperText className="errorText">
                        {Message}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  {countyValue.length ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className="form-dropdown"
                        fullWidth
                        variant="standard"
                      >
                        <InputLabel>County/District</InputLabel>
                        <Select
                          name="county"
                          value={formstate.county}
                          onChange={handleChangeForm}
                          required
                        >
                          {countyValue.map((item) => (
                            <MenuItem value={item[0]}>{item[0]}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {FieldName === "county" ? (
                        <FormHelperText className="errorText">
                          {Message}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        className="form_textfield"
                        label="City/Estate*"
                        variant="standard"
                        name="city"
                        inputProps={{ maxLength: 40, autoComplete: "none" }}
                        value={formstate.city}
                        onChange={handleChangeForm}
                        onKeyUp={EnterKeynext}
                        error={Boolean(isError && FieldName === "city")}
                        helperText={FieldName === "city" && Message}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      className="form_textfield"
                      label="Zipcode/Postal Code*"
                      variant="standard"
                      name="zipCode"
                      inputProps={{ maxLength: 40, autoComplete: "none" }}
                      value={formstate.zipCode}
                      onKeyUp={EnterKeynext}
                      onChange={handleChangeForm}
                      error={Boolean(isError && FieldName === "zipCode")}
                      helperText={FieldName === "zipCode" && Message}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>
                        What is your immigration status? *
                      </InputLabel>
                      <Select
                        name="immigrationStatus"
                        value={formstate.immigrationStatus}
                        onChange={handleChangeForm}
                      >
                        <MenuItem value={"BVIslander/Belonger"}>
                          BVIslander/Belonger
                        </MenuItem>
                        <MenuItem value={"BVI Resident"}>BVI Resident</MenuItem>
                        <MenuItem value={"Tourist"}>Tourist</MenuItem>
                      </Select>
                      {FieldName === "immigrationStatus" ? (
                        <FormHelperText className="errorText">
                          {Message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider
                  style={{ backgroundColor: "#00afb9", height: "1px" }}
                />
                <Typography className="mt mx" variant="subtitle1">
                  Additional Demographics
                </Typography>
                <Grid container spacing={4} className="px py mb" ref={myRef1}>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>Race *</InputLabel>
                      <Select
                        name="race"
                        value={formstate.race}
                        onChange={handleChangeForm}
                        required
                      >
                        {raceValues.map((item) => (
                          <MenuItem value={item.raceName}>
                            {item.raceName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {FieldName === "race" ? (
                      <FormHelperText className="errorText">
                        {Message}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>Gender *</InputLabel>
                      <Select
                        name="gender"
                        value={formstate.gender}
                        onChange={handleChangeForm}
                        required
                      >
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Ambiguous"}>Non-binary</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                    {FieldName === "gender" ? (
                      <FormHelperText className="errorText">
                        {Message}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sm={12}
                    style={{ paddingTop: "15px" }}
                  >
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>Ethnicity *</InputLabel>
                      <Select
                        aria-label="ethnicity"
                        name="ethnicity"
                        value={formstate.ethnicity}
                        onChange={handleChangeForm}
                        required
                      >
                        {ethnicityValues.map((item) => (
                          <MenuItem value={item.ethinicityName}>
                            {item.ethinicityName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {FieldName === "ethnicity" ? (
                      <FormHelperText className="errorText">
                        {Message}
                      </FormHelperText>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend">
                      Is the patient a member of one of these groups? *
                    </FormLabel>
                    <RadioGroup
                      aria-label="member"
                      name="member"
                      className="mt"
                      value={formstate.member}
                      onChange={handleChangeForm}
                    >
                      <Grid container spacing={2} style={{ marginTop: "5px" }}>
                        <Grid item xs={12} md={6} sm={12} className="gridRadio">
                          <FormControlLabel
                            className="radiovalues"
                            value="First response/Healthcare"
                            control={<Radio className="mx" />}
                            label="First responder/Healthcare worker"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} className="gridRadio">
                          <FormControlLabel
                            className="radiovalues"
                            value="Congregate facility"
                            control={<Radio className="mx" />}
                            label="Congregate facility worker/resident"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} className="gridRadio">
                          <FormControlLabel
                            className="radiovalues"
                            value="Hospitality/Transportation"
                            control={<Radio className="mx" />}
                            label="Hospitality/Transportation worker"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} sm={12} className="gridRadio">
                          <FormControlLabel
                            className="radiovalues"
                            value="In-person school/daycare"
                            control={<Radio className="mx" />}
                            label="In-person school/daycare worker"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} className="gridRadio">
                          <FormControlLabel
                            className="radiovalues"
                            value="None"
                            control={<Radio className="mx" />}
                            label="Not a priority group"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} className="gridRadio">
                          <FormControlLabel
                            className="radiovalues"
                            value="Others"
                            control={<Radio className="mx" />}
                            label="Other Group (Please indicate below)"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                    {FieldName === "member" ? (
                      <FormHelperText className="errorText">
                        {Message}
                      </FormHelperText>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  {formstate.member && (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        className="form_textfield"
                        id="standard-basic"
                        label="Occupation/Affiliation *"
                        variant="standard"
                        inputProps={{ maxLength: 40, autoComplete: "none" }}
                        onKeyUp={EnterKeynext}
                        onChange={handleChangeForm}
                        name="occupation"
                        value={formstate.occupation}
                        error={Boolean(isError && FieldName === "occupation")}
                        helperText={FieldName === "occupation" && Message}
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider
                  style={{ backgroundColor: "#00afb9", height: "1px" }}
                />
                <Typography className="pt mx" variant="subtitle1">
                  Recent Health Updates
                </Typography>
                <Grid container spacing={4} className="px py" ref={myRef2}>
                  {formstate.gender == "Female" && (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormLabel component="legend">Pregnancy Status</FormLabel>
                      <RadioGroup
                        aria-label="pregnancy"
                        name="pregnancy"
                        className="mt"
                        style={{ display: "flex", flexDirection: "row" }}
                        value={formstate.pregnancy}
                        onChange={handleChangeForm}
                      >
                        <FormControlLabel
                          className="radiovalues"
                          value="Y"
                          control={<Radio className="mx" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          className="radiovalues"
                          value="N"
                          control={<Radio className="mx" />}
                          label="No"
                        />
                        <FormControlLabel
                          className="radiovalues"
                          value="U"
                          control={<Radio className="mx" />}
                          label="Unknown"
                        />
                      </RadioGroup>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend">
                      Have you been experiencing any Covid-19 symptoms? *
                    </FormLabel>
                    <RadioGroup
                      aria-label="symptoms"
                      name="symptoms"
                      className="mt"
                      style={{ display: "flex", flexDirection: "row" }}
                      value={symptoms}
                      onChange={handleChangeSymptoms}
                    >
                      <FormControlLabel
                        className="radiovalues"
                        value="Y"
                        control={<Radio className="mx" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        className="radiovalues"
                        value="N"
                        control={<Radio className="mx" />}
                        label="No"
                      />
                    </RadioGroup>
                    {FieldName === "symptoms" ? (
                      <FormHelperText className="errorText">
                        {Message}
                      </FormHelperText>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  {symptoms == "Y" ? (
                    <>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Shortness_of_breath}
                              onChange={handleChangeSymptomsChecks}
                              name="Shortness_of_breath"
                              value="Shortness of breath"
                            />
                          }
                          label="Shortness of breath"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Cough}
                              onChange={handleChangeSymptomsChecks}
                              name="Cough"
                              value="Cough"
                            />
                          }
                          label="Cough"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Chills}
                              onChange={handleChangeSymptomsChecks}
                              name="Chills"
                              value="Chills"
                            />
                          }
                          label="Chills"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Fever}
                              onChange={handleChangeSymptomsChecks}
                              name="Fever"
                              value="Fever >100.5"
                            />
                          }
                          label="Fever >100.5"
                        />
                      </Grid>

                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Difficulty_breathing}
                              onChange={handleChangeSymptomsChecks}
                              name="Difficulty_breathing"
                              value="Difficulty breathing"
                            />
                          }
                          label="Difficulty breathing"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Headache}
                              onChange={handleChangeSymptomsChecks}
                              name="Headache"
                              value="Headache"
                            />
                          }
                          label="Headache"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Fatigue}
                              onChange={handleChangeSymptomsChecks}
                              name="Fatigue"
                              value="Fatigue"
                            />
                          }
                          label="Fatigue"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Diarrhea}
                              onChange={handleChangeSymptomsChecks}
                              name="Diarrhea"
                              value="Diarrhea"
                            />
                          }
                          label="Diarrhea"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Vomiting}
                              onChange={handleChangeSymptomsChecks}
                              name="Vomiting"
                              value="Vomiting"
                            />
                          }
                          label="Vomiting"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Sore_Throat}
                              onChange={handleChangeSymptomsChecks}
                              name="Sore_Throat"
                              value="Sore Throat"
                            />
                          }
                          label="Sore Throat"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Nausea}
                              onChange={handleChangeSymptomsChecks}
                              name="Nausea"
                              value="Nausea"
                            />
                          }
                          label="Nausea"
                        />
                      </Grid>

                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Loss_of_taste}
                              onChange={handleChangeSymptomsChecks}
                              name="Loss_of_taste"
                              value="Loss of taste"
                            />
                          }
                          label={
                            <Typography variant="inherit" component="span">
                              {/* Loss of taste <br /> and / or smell{" "} */}
                              Loss of taste and / or smell
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={symptomsChecked.Body_and_or_muscle_aches}
                              onChange={handleChangeSymptomsChecks}
                              name="Body_and_or_muscle_aches"
                              value="Body and/or muscle aches"
                            />
                          }
                          label="Body and/or muscle aches"
                        />
                      </Grid>
                      <Grid item xs={12} md={9} sm={12} className="gridCheck">
                        <FormControlLabel
                          className="checkValues"
                          control={
                            <Checkbox
                              className="mx"
                              checked={
                                symptomsChecked.Congestion_and_or_runny_nose
                              }
                              onChange={handleChangeSymptomsChecks}
                              name="Congestion_and_or_runny_nose"
                              value="Congestion and/or runny nose"
                            />
                          }
                          label="Congestion and/or runny nose"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sm={12} className="gridCheck">
                        {FieldName === "symptomsChecked" ? (
                          <FormHelperText className="errorText">
                            {Message}
                          </FormHelperText>
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </>
                  ) : null}

                  {symptoms === "Y" &&
                    Object.keys(symptomsChecked).filter(
                      (e) => symptomsChecked[e] === true
                    ).length > 0 &&
                    symptomsChecked !== undefined ? (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sm={12}
                      style={{ paddingTop: "10px" }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ width: "100%" }}
                          clearable
                          className="dateSelection"
                          value={symptomsStarteddate}
                          onChange={(date) => handleChangeSymptomsStart(date)}
                          format="MM/dd/yyyy"
                          label="Symptom start date"
                          maxDate={new Date()}
                          required
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                      {FieldName === "symptomsCheckedDate" ? (
                        <FormHelperText className="errorText">
                          {Message}
                        </FormHelperText>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={12} sm={12}>
                    <div className=" d-flex">
                      <div>
                        <button className="btn" onClick={back}>
                          <ArrowBackIosNewIcon
                            style={{ width: "20px", marginRight: "5px" }}
                          />
                          Back
                        </button>
                      </div>
                      <div>
                        <button className="btn" onClick={next3}>
                          Next
                          <ArrowForwardIosIcon
                            style={{ width: "20px", marginLeft: "5px" }}
                          />
                        </button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ThirdStepper;
